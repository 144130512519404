:root {
  --gc1: rgba(245, 0, 87, 0.75);
  --gc2: rgba(173, 255, 47, 0.75);
  --gc3: rgba(24, 107, 204, 0.75);
}

.glitch {
  position: relative;

  text-shadow: 0.05em 0 0 var(--gc1), -0.025em -0.05em 0 var(--gc2),
    0.025em 0.05em 0 var(--gc3);

  animation: glitch 500ms infinite;
}

.glitch span {
  position: absolute;
  top: 0;
  left: 0;
}

.glitch span:first-child {
  animation: glitch 650ms infinite;
  clip-path: polygon(0 0, 100% 0, 100% 45%, 0 45%);
  transform: translate(-0.025em, -0.0125em);
  /* color: green; */
  opacity: 0.8;
}

.glitch span:last-child {
  animation: glitch 375ms infinite;
  clip-path: polygon(0 80%, 100% 20%, 100% 100%, 0 100%);
  transform: translate(0.0125em, 0.025em);
  /* color: red; */
  opacity: 0.8;
}

/* https://web.dev/prefers-reduced-motion/#(bonus)-forcing-reduced-motion-on-all-websites */

@keyframes glitch {
  0% {
    text-shadow: 0.05em 0 0 var(--gc1), -0.05em -0.025em 0 var(--gc2),
      -0.025em 0.05em 0 var(--gc3);
  }
  14% {
    text-shadow: 0.05em 0 0 var(--gc1), -0.05em -0.025em 0 var(--gc2),
      -0.025em 0.05em 0 var(--gc3);
  }
  15% {
    text-shadow: -0.05em -0.025em 0 var(--gc1), 0.025em 0.025em 0 var(--gc2),
      -0.05em -0.05em 0 var(--gc3);
  }
  49% {
    text-shadow: -0.05em -0.025em 0 var(--gc1), 0.025em 0.025em 0 var(--gc2),
      -0.05em -0.05em 0 var(--gc3);
  }
  50% {
    text-shadow: 0.025em 0.05em 0 var(--gc1), 0.05em 0 0 var(--gc2),
      0 -0.05em 0 var(--gc3);
  }
  99% {
    text-shadow: 0.025em 0.05em 0 var(--gc1), 0.05em 0 0 var(--gc2),
      0 -0.05em 0 var(--gc3);
  }
  100% {
    text-shadow: -0.025em 0 0 var(--gc1), -0.025em -0.025em 0 var(--gc2),
      -0.025em -0.05em 0 var(--gc3);
  }
}

@media (prefers-reduced-motion: reduce) {
  *,
  ::before,
  ::after {
    animation-delay: -1ms !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
    background-attachment: initial !important;
    scroll-behavior: auto !important;
    transition-duration: 0s !important;
    transition-delay: 0s !important;
  }
}
