:root {
  --gc1: rgba(245, 0, 87, 0.75);
  --gc2: rgba(173, 255, 47, 0.75);
  --gc3: rgba(24, 107, 204, 0.75);
}

.glitchbutton {
  position: relative;
  animation: glitchbutton 500ms infinite;
}

.glitchbutton::before,
.glitchbutton::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: inherit;
}

.glitchbutton::before {
  box-shadow: 0.05em 0 0 var(--gc1), -0.025em -0.05em 0 var(--gc2),
    0.025em 0.05em 0 var(--gc3);
  animation: glitchbutton 650ms infinite;
}

.glitchbutton::after {
  box-shadow: -0.05em -0.025em 0 var(--gc1), 0.025em 0.025em 0 var(--gc2),
    -0.05em -0.05em 0 var(--gc3);
  animation: glitchbutton 375ms infinite;
}

@keyframes glitchbutton {
  0% {
    transform: translate(0.05em, 0);
  }
  14% {
    transform: translate(0.05em, 0);
  }
  15% {
    transform: translate(-0.05em, -0.025em);
  }
  49% {
    transform: translate(-0.05em, -0.025em);
  }
  50% {
    transform: translate(0.025em, 0.05em);
  }
  99% {
    transform: translate(0.025em, 0.05em);
  }
  100% {
    transform: translate(-0.025em, 0);
  }
}

@media (prefers-reduced-motion: reduce) {
  *,
  ::before,
  ::after {
    animation-delay: -1ms !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
    background-attachment: initial !important;
    scroll-behavior: auto !important;
    transition-duration: 0s !important;
    transition-delay: 0s !important;
  }
}
